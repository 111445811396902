import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { LoadingController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
// import { Subscription } from 'rxjs-compat/Subscription';
import { ApiService } from './api.service';
import { CartService } from './cart.service';
import { DesignService } from './design.service';
// import { Subscription }   from 'rxjs-compat/Subscription';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private bg = '#ffffff';
  private color = "#000000";
  public configuration;

  //Cookie Consent
  // private popupOpenSubscription: Subscription;
  // private popupCloseSubscription: Subscription;
  // private initializeSubscription: Subscription;
  // private statusChangeSubscription: Subscription;
  // private revokeChoiceSubscription: Subscription;
  // private noCookieLawSubscription: Subscription;
  //!Cookie Consent
  private hasStartBeenCalled = false;

  constructor(public loadingController: LoadingController,
    private storage : Storage,
    private cart : CartService,
    private api : ApiService,
    private splash : SplashScreen,
    private design : DesignService,
    private ccService: NgcCookieConsentService,
    private translateService:TranslateService,
    private route: ActivatedRoute,
    public platform: Platform,
    private router: Router,
    private location: Location,
    @Inject(DOCUMENT) private document: Document) {
       if (this.api.set_store()) {
        this.startApp()
       }
    }

    ionViewDidEnter() {
      this.splash.show();
    }

    ionViewWillEnter(){
      console.log('open');
      
      this.splash.show();
    }


    ngOnInit() {
      this.splash.show();
      console.log('jejeje');
      
      if (!this.api.set_store()) {
        this.router.events
          .pipe(filter(event => event instanceof NavigationStart))
          .subscribe((event: NavigationStart) => {
            if (!/redirect-app/.test(event.url) && !this.api.set_store()) {
              this.router.navigateByUrl('/redirect-app/').then(() => {
                console.log('Redirection vers /redirect-app/ effectuée.');
              });
            }
            if(this.api.set_store()){
              if (/redirect-app/.test(event.url)){
                this.location.back();
                window.location.reload()
              }
              this.startApp()
            }
          });
      } else {
        console.log('ID Store est défini, pas de redirection nécessaire.');
        this.router.events
        .pipe(filter(event => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          if(this.api.set_store()){
            if (/redirect-app/.test(event.url)){
              this.location.back();
              window.location.reload()
            }
          }
        });
      }
    }
    startApp(){
      if (!this.hasStartBeenCalled) {
        this.hasStartBeenCalled = true
      var self = this;
        this.splash.show();
        this.platform.ready().then(async () => {
          setTimeout(()=>{
            self.splash.hide();
          }, 3000)
        })
        this.api.getStore().subscribe(async (data) => {
          this.api.configuration = data;
          self.setMetas();
          this.configuration = data;
          var id_sup = this.configuration.id_category_supplement;
          if (id_sup > 0) {
            this.api.getProductsByIdCategory(id_sup).subscribe((data) => {
              this.api.configuration.supplements = data;
            })
          }
          const head = this.document.getElementsByTagName('head')[0];
          const style = this.document.createElement('link');
          style.id = 'client-theme';
          style.rel = 'stylesheet';
          style.href = `theme_${data.template}.css`;
          head.appendChild(style);
          this.api.getCategories().subscribe((data) => {
            var sorted = [];
            for (var one of data) {
              if (one.id_category != this.api.configuration.id_category_supplement) {
                sorted.push(one);
              }
            }
            sorted.sort(function(a, b){
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
            })
            this.api.setCategories(sorted)
            this.splash.hide();
          })
        })
        this.logUser();
        this.design.loadDesign();
        this.cart.init(this.api.getIdStore());
      }
    }
    async logUser(){
      var user = await this.storage.get('user');
      if (user) {
        user = JSON.parse(user);
        this.api.loginToken(user.id_user, user.token).subscribe((data) => {
          this.api.user = data;
          this.api.saveUser();
          this.api.addAdminPage();
        });
      }
    }


    //
    //Cookie Consent
    //

    translateCookieConsent(callback){
      this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
      // Override default messages with the translated ones
      this.ccService.getConfig().content.header = "RGPD Cookie";
      this.ccService.getConfig().content.dismiss = "Refuser";
      this.ccService.getConfig().content.allow = "Autoriser";
      this.ccService.getConfig().content.deny = "Décliner";
      this.ccService.getConfig().content.link = "En savoir plus";
      this.ccService.getConfig().content.policy = "Politique de confidentialité";

      this.ccService.destroy();//remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      /*
      */
      callback();
    }

    // loadCookieConsent(){
    //   if (this.platform.is('capacitor') || this.platform.is('cordova')) {
    //     return ;
    //   }
    //   var conf = this.ccService.getConfig();
    //   this.translateCookieConsent(() => {
    //     // subscribe to cookieconsent observables to react to main events
    //     this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
    //       var conf = this.ccService.getConfig();
    //       // you can use this.ccService.getConfig() to do stuff...
    //     });
    //     this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    //       () => {
    //         var conf = this.ccService.getConfig();
    //         // you can use this.ccService.getConfig() to do stuff...
    //       });
    //       this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
    //         var conf = this.ccService.getConfig();
    //         // you can use this.ccService.getConfig() to do stuff...
    //       });

    //       this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
    //         var conf = this.ccService.getConfig();
    //       });
    //       this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
    //         var conf = this.ccService.getConfig();
    //       });
    //       this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
    //         var conf = this.ccService.getConfig();
    //       });
    //     });
    //   }

      // destroyCookieConsent(){
      //   this.popupOpenSubscription.unsubscribe();
      //   this.popupCloseSubscription.unsubscribe();
      //   this.initializeSubscription.unsubscribe();
      //   this.statusChangeSubscription.unsubscribe();
      //   this.revokeChoiceSubscription.unsubscribe();
      //   this.noCookieLawSubscription.unsubscribe();
      // }

      setMeta(property, content){
        var link = document.createElement('meta');
        link.setAttribute('property', property);
        link.content = content;
        document.getElementsByTagName('head')[0].appendChild(link);
      }

      setMetas(){
        this.setMeta('description', this.api.configuration.meta_description)
        this.setMeta('og:title', this.api.configuration.meta_fb_title)
        this.setMeta('og:description', this.api.configuration.meta_fb_description)
        this.setMeta('og:url', this.api.configuration.domain_name)
        this.setMeta('og:image', this.api.configuration.img_url)
      }
    }
